import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'


import SEO from '../components/SEO'

import { ImageList } from '../components/Gallery'
import Layout from '../components/Layout'
import HeroNobackground from '../components/HeroNoBackground'
import { space } from '../utils/tokens'
import { css } from 'styled-components'

if (typeof window !== `undefined`) {
  window.imagesToShow = 12
}

const AlbumTemplate = (props) => {
  const { images, title, fields, heroImage } = props.data.contentfulAlbum
  
  let imagesToShow = 12

  if (typeof window !== `undefined`) {
    imagesToShow = window.imagesToShow
  }

  const [count, setCount] = useState(imagesToShow)




  useEffect(() => {
    return () => {
      window.postsToShow = count
    }
  })

  const reverseArray = (arr) => {
    let newArray = []
    for (let i = arr.length - 1; i >= 0; i--) {
      newArray.push(arr[i])
    }

    return newArray
  }

  const reversedImages = reverseArray(images)

  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <SEO
        customTitle
        postSEO
        postNode={{
          title: `${title} Gallery | PrintAWorld`,
          metaDescription: {
            internal: {
              content: `A gallery of ${title} projects that we did for fun and for clients. Get an idea of how we can fabricate a concept using 3D printing, 3D Modeling, 3D scanning and Laser cutting.`,
            },
          },
          heroImage: heroImage,
        }}
        pagePath={fields.path}
      />
      <HeroNobackground title={`${title}`} />
      <div css={css({ paddingBottom: `${space[5]}px`})}>
        <ImageList
          images={reversedImages}
          count={count}
          onButtonClick={() => setCount(count + 12)}
        />
      </div>
    </Layout>
  )
}

export default AlbumTemplate

export const pageQuery = graphql`
  query AlbumPhotosQuery($slug: String!) {
    contentfulAlbum(slug: { eq: $slug }) {
      title
      images {
        title
        id
        thumbnail: fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        fluid(maxWidth: 3000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        fixed(quality: 50) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
        lightBoxImage: fluid(maxWidth: 1440, quality: 80) {
          src
        }
      }
      fields {
        path
      }
      heroImage: coverPhoto {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
    }
  }
`